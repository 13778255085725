import { useMemo } from 'react';

// components
import BlogPost from 'src/components/BlogPost';

// hooks
import usePostsData from 'src/cms/data/blog/usePostsData';
import useWindowSize from 'src/hooks/useWindowSize';

// styles
import * as style from './RecommendedPosts.module.scss';

const RecommendedPosts = ({ id }: { id: string }) => {
  // hooks
  const { postList } = usePostsData();
  const { isTablet, isMobile } = useWindowSize();
  const currentIndex = useMemo(
    () => postList.findIndex((post) => post.id === id),
    []
  );

  const [previousIndex, nextIndex] = useMemo(() => {
    if (currentIndex === 0) {
      return [postList.length - 1, 1];
    } else if (currentIndex === postList.length - 1) {
      return [currentIndex - 1, 0];
    }

    return [currentIndex - 1, currentIndex + 1];
  }, [postList]);

  if (postList.length <= 2) {
    return null;
  }

  return (
    <div className={style.recommendedRoot}>
      {!(isTablet || isMobile) && (
        <BlogPost post={postList[previousIndex]} subtitle={'Previous Post'} />
      )}
      <BlogPost post={postList[nextIndex]} subtitle={'Next Post'} />
    </div>
  );
};

export default RecommendedPosts;
