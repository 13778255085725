import { FC } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
// components
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';

import * as style from './Intro.module.scss';

type Props = {
  title: string;
  subtitle: string;
  image: IGatsbyImageData;
};

const Intro: FC<Props> = ({ title, subtitle, image }) => {
  return (
    <Section className={style.root}>
      <div className={style.content}>
        <GatsbyImage alt={title} image={image} className={style.image} />
        <div className={style.info}>
          <Typography className={style.desktopTextAnimated} variant='h1'>
            {title}
          </Typography>
          <Typography variant='body' className={style.desktopTextAnimated}>
            {subtitle}
          </Typography>
        </div>
      </div>
    </Section>
  );
};

export default Intro;
