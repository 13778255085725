import { useEffect, useMemo } from 'react';
import showdown from 'showdown';
import {
  swapImageSourcesWithRequire,
  swapLinksAttributesWithRequire,
} from 'src/services/helpers';
import * as style from './Content.module.scss';

const converter = new showdown.Converter();

const Content = ({ body }: { body: string }) => {
  useEffect(() => {
    swapImageSourcesWithRequire('blog/images', 'blog');
    swapLinksAttributesWithRequire('https://seedium.io/');
  }, []);

  const html = useMemo(() => converter.makeHtml(body), [body]);

  return (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{ __html: html }} className={style.blog} />
  );
};

export default Content;
