import { FC, useMemo } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

// components
import Typography from 'src/components/Typography/Typography';

// hooks
import useWindowSize from 'src/hooks/useWindowSize';

// styles
import * as style from './BlogPost.module.scss';

import { PostBlog } from 'src/cms/data/blog/usePostsData';

const BlogPost: FC<{
  post: PostBlog;
  subtitle: string;
}> = ({ post, subtitle }) => {
  const { isTablet, isMobile } = useWindowSize();
  const isLittleScreen = useMemo(() => isMobile && isTablet, []);

  return (
    <Link to={`/blog/${post.id}`} className={style.blogPost}>
      <div
        className={style.bgImage}
        style={{
          backgroundImage: `url(${post.image.childImageSharp.gatsbyImageData})`,
        }}
      />
      <div
        className={classNames(
          style.infoWrapper,
          subtitle === 'Next Post' && style.leftSide
        )}
      >
        <Typography variant='body'>{subtitle}</Typography>
        <Typography
          variant={isLittleScreen ? 'h4' : 'h3'}
          className={style.titleIndent}
        >
          {post.title}
        </Typography>
      </div>
    </Link>
  );
};

export default BlogPost;
